<template>
    <div class="UploadImages">
        <div class="UploadImagesCon clearfloat">
            <div class="UploadImagesItem" v-for="(n,index) in listData" v-loading="n.loading">
                <el-image  :src="n[fields.fileName] | fileAddress" class="image"></el-image>
                <div class="operation" v-if="!disabled">
                    <i class="el-icon-delete" @click="del(n,index)"></i>
                </div>
            </div>

            <div class="UploadImagesItem" v-if="!disabled">
                <Upload @upload="upload" :multiple="true" :fields="fields">
                    <div slot="button" class="UploadImageBtn">
                        <i class="el-icon-plus avatar-uploader-icon"></i>
                    </div>
                </Upload>

            </div>
        </div>
    </div>
</template>

<script>

    export default {
        props: {
            disabled: {
                type: Boolean,
                default: false
            },
            fields:{
                type: Object,
                default:function(){
                    return {
                        fileName:'fileName',
                        fileAddress:'fileAddress'
                    }
                }
            },
            value: {
                type: Array,
                default:function(){
                    return []
                }
            },
        },
        components: {
            Upload: () => import("./Upload.vue"),
        },
        model: {
            prop: 'value',
            event: 'change'
        },
        data() {
            return {
                listData:[]
            };
        },
        computed: {},
        watch: {
            value:{
                immediate:true,//初始化立即执行
                handler: function (newVal){
                    this.listData = newVal||[];
                }
            },
        },
        mounted() {},
        methods: {
            upload(r){
                r.some(res=>{
                    this.listData.push(res);
                });
                this.$emit('change', this.listData);
            },
            del(n,i){
                if(n.id){
                    this.$confirm("确定删除该数据, 是否继续?", "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning"
                    }).then(() => {
                        this.$api['admin/landUseInfo'].deleteImg({ids:[n.id]}).then(res => {

                            this.$emit('del', this.listData[i],i);
                            this.listData.splice(i,1);
                            this.$emit('change', this.listData);


                        }).catch(err => {
                            console.log(err);
                        });
                    }).catch(() => {//用户取消

                    });
                }else {
                    this.$emit('del', this.listData[i],i);
                    this.listData.splice(i,1);
                    this.$emit('change', this.listData);
                };

            },
        }
    };
</script>

<style lang="scss" scoped>
.UploadImages {
    overflow:hidden;
    .UploadImagesCon {
        position:relative;
        right:20px;
        width:calc(100% + 20px);
        margin-bottom:-20px;
        display: flex;
        flex-wrap: wrap;
        .UploadImagesItem {
            position:relative;
            text-align:center;
            border: 1px dashed #d9d9d9;
            margin:0 0 20px 20px;
            &:hover {
                .operation {
                    opacity:1;
                    transition:.1s cubic-bezier(.92,1.1,.71,.94);
                }
            }
            .operation {
                position:absolute;
                top:0;
                left:0;
                right:0;
                bottom:0;
                background:rgba(0,0,0,.8);
                opacity:0;
                .el-icon-delete {
                    cursor:pointer;
                    position:absolute;
                    top:50%;
                    left:50%;
                    color:#fff;
                    font-size:24px;
                    transform: translate(-50%,-50%);
                }
            }
            .el-image {
                display:block;
                width: 100px;
                height: 100px;
                line-height: 100px;
            }
            /deep/.UploadImageBtn{
                font-size: 28px;
                color: #8c939d;
                width: 100px;
                height: 100px;
                line-height: 100px;
                text-align: center;
                border-radius: 6px;
                cursor: pointer;
                .image {
                    max-width:100%;
                    max-height:100%;
                }
            }
        }
    }
}
</style>
